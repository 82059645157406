<template>
    <div>
        <template v-if="item.coupon_message">
            <template v-if="item.coupon_applied">
                <div class="badge badge-success">{{ item.coupon_message }}</div>
            </template>
            <template v-else>
                <div class="badge badge-light">{{ item.coupon_message }}</div>
            </template>
        </template>
        <template v-if="editable && hasOffer(item)">
            <div class="Cart__item__offer my-2">
                <template v-if="hasAdditionalProductOffer(item)">
                    <div class="custom-checkbox custom-control">
                        <input :id="`cart-additional-offer-${item.id}`" class="custom-control-input" type="checkbox" :checked="isOfferApplied(item)" @change="handleAdditionalOfferCheckboxChanged">
                        <label class="custom-control-label Cart__item__offer-label font-weight-normal" :for="`cart-additional-offer-${item.id}`">
                            <span class="d-none d-sm-inline">Ajoutez l'article : </span>
                            <span v-html="item.offer_message"></span>
                        </label>
                    </div>
                </template>
                <template v-else-if="hasQuantityDiscountOffer(item)">
                    <div class="Cart__item__offer-label">
                        Bénéficiez de <span v-html="item.offer_message"></span>
                    </div>
                </template>
                <template v-else-if="hasPackDiscountOffer(item)">
                    <div class="Cart__item__offer-label">
                        Bénéficiez de <span v-html="item.offer_message"></span>
                    </div>
                </template>
                <template v-else-if="hasLeastExpensiveIsFreeOffer(item)">
                    <div class="Cart__item__offer-label">
                        <span v-html="item.offer_message"></span>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import {
        hasAdditionalProductOffer,
        hasLeastExpensiveIsFreeOffer,
        hasOffer,
        hasPackDiscountOffer,
        hasQuantityDiscountOffer,
        isOfferApplied,
    } from "../../util/cart";

    export default {
        props: {
            item: Object,
            editable: Boolean,
        },
        methods: {
            hasOffer,
            hasAdditionalProductOffer,
            hasPackDiscountOffer,
            hasQuantityDiscountOffer,
            hasLeastExpensiveIsFreeOffer,

            isOfferApplied,

            handleAdditionalOfferCheckboxChanged(e) {
                this.$emit('toggle-offer', this.item, e.target.checked);
            },
        }
    }
</script>
