

/**
 * @type {{
 *  app: {debug},
 *  meilisearch: {host, search_only_key},
 *  ek: {site_name, site_id},
 *  scout: {prefix},
 *  sentry: {env, enabled, dsn}
 * }}
 */
export const config = window.legacyConfig; // defined in layouts/master.blade.php
