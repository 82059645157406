import './polyfill';
import './util/sentry';
import './api';

import Vue from 'vue';
// import Vuex from 'vuex';
import {
    AlertPlugin,
    // CarouselPlugin,
    CollapsePlugin,
    DropdownPlugin,
    FormCheckboxPlugin,
    FormRadioPlugin,
    ModalPlugin,
    // TabsPlugin,
    TooltipPlugin,
    VBScrollspyPlugin,
} from 'bootstrap-vue';

// import {install as VueGoogleMaps} from './vendor/vue2-google-maps';
// import VueAwesomeSwiper from 'vue-awesome-swiper';
import PortalVue from 'portal-vue';

import components from './components';
import directives from './directives';
import store from './store';

// Vue.use(Vuex);
Vue.use(PortalVue);

Vue.use(AlertPlugin);
// Vue.use(CarouselPlugin);
Vue.use(DropdownPlugin);
Vue.use(CollapsePlugin);
// Vue.use(TabsPlugin);
Vue.use(ModalPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(TooltipPlugin);
Vue.use(VBScrollspyPlugin);

// Vue.use(VueAwesomeSwiper);

// Vue.use(VueGoogleMaps, {
//     installComponents: false
// });

// register components dynamically to enable autocompletion in blade
Object.keys(components).forEach((name) => {
    Vue.component(name, components[name]);
});

document.querySelectorAll('[data-legacy-vue]').forEach((el) => {
    const app = new Vue({
        directives: {
            ...directives,
            // inViewport,
        },

        data() {
            return {
                viewportWidth: window.innerWidth,
            }
        },

        // store: new Vuex.Store(store),

        config: {
            devtools: __DEV__,
        },
    });

    app.$mount(el);
});
