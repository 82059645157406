<template>
    <div class="card Cart__side-card Cart__side-card--summary" :class="classes">
        <div class="card-body">
            <div class="row justify-content-end align-items-center">
                <div class="col">
                    <template v-if="isShipFromStore && canProceed">
                        <div class="row align-items-baseline mx-n1 mb-2">
                            <div class="col px-1">
                                <h6 class="mb-0">Frais de livraison&nbsp;:</h6>
                            </div>
                            <div class="col-auto px-1">
                                <template v-if="isMinimumShippingFees">
                                    <span class="text-muted mr-1" style="font-size: .75rem">à partir de</span>
                                </template>
                                {{ shippingFees }}
                            </div>
                        </div>
                    </template>
                    <div class="row align-items-center mx-n1">
                        <div class="col px-1">
                            <h6 class="bold-title mb-0">Total du panier&nbsp;:</h6>
                        </div>
                        <div class="col-auto px-1">
                            <template v-if="loading">
                                <Loading class="Cart__side-loading" inline />
                            </template>
                            <template v-else-if="canProceed">
                                <Price class="Cart__side-total" :price="total" thin />
                            </template>
                            <template v-else>
                                <div class="Cart__side-total text-muted price font-weight-normal">–</div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-none d-lg-block my-3">
                    <div class="Cart__card-separator"></div>
                </div>
                <div class="col-sm-auto col-lg-12 mt-2 mt-sm-0">
                    <template v-if="canProceed">
                        <button class="btn btn-primary btn-lg btn-block" type="submit">
                            Valider le panier
                        </button>
                    </template>
                    <template v-else>
                        <div tabindex="0" ref="proceedCart">
                            <button class="btn btn-primary btn-lg btn-block" style="pointer-events: none;" disabled>
                                Valider le panier
                            </button>
                        </div>
                        <b-tooltip custom-class="Cart__tooltip" container="#legacy-container" :target="()=>$refs.proceedCart" triggers="focus">
                            <template v-if="!hasActiveItems">
                                <NoItemsAvailableMessage
                                    :cart="cart"
                                    :store-name="storeName"
                                    button
                                    @delivery-mode-click="handleChooseDeliveryClicked"
                                />
                            </template>
                            <template v-else-if="!hasDriveEnabled">
                                <DriveDisabledMessage
                                    :store-name="storeName"
                                    @change-store-click="handleChangeStoreClicked"
                                    @delivery-mode-click="handleChooseDeliveryClicked"
                                />
                            </template>
                        </b-tooltip>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { BTooltip } from 'bootstrap-vue';
    import Price from "../Price.vue";
    import NoItemsAvailableMessage from "./messages/NoItemsAvailable.vue";
    import DriveDisabledMessage from "./messages/DriveDisabled.vue";
    import {
        isShipFromStore,
        cartTotal,
        cartShippingFees,
        cartActiveItems,
        isClickAndCollect,
        isMinimumShippingFees
    } from "../../util/cart";
    import Loading from '../Loading.vue';

    export default {
        components: {
            Price,
            Loading,
            BTooltip,
            NoItemsAvailableMessage,
            DriveDisabledMessage,
        },
        props: {
            cart: {
                type: Object,
                required: true,
            },
            store: {
                required: true,
                validator: s => typeof s === 'object',
            },
            orderUrl: String,
            sticky: Boolean,
            loading: Boolean,
        },
        computed: {
            classes() {
                return {
                    'Cart__side-card--sticky': this.sticky,
                }
            },
            isShipFromStore() {
                return isShipFromStore(this.cart);
            },
            total() {
                return cartTotal(this.cart);
            },
            shippingFees() {
                return cartShippingFees(this.cart);
            },
            isMinimumShippingFees() {
                return isMinimumShippingFees(this.cart);
            },
            storeName() {
                return this.store?.full_name;
            },
            hasActiveItems() {
                const activeItems = cartActiveItems(this.cart);
                return activeItems.length > 0;
            },
            hasDriveEnabled() {
                return isClickAndCollect(this.cart)
                    ? !!this.store?.is_drive
                    : true;
            },
            canProceed() {
                return this.hasActiveItems && this.hasDriveEnabled;
            },
        },
        methods: {
            handleChooseDeliveryClicked(mode) {
                this.$emit('delivery-mode-change', mode);
            },
            handleChangeStoreClicked() {
                this.$emit('change-store-click');
            },
        }
    }
</script>
