<template>
    <div>
        <template v-if="value">
            <div class="card" :class="{ 'border-danger': error }">
                <div class="card-body">
                    <AddressLabel
                        :address="value"
                        :show-phone="false"
                    />
                </div>
                <template v-if="$slots['card-footer']">
                    <div class="card-footer">
                        <slot name="card-footer" />
                    </div>
                </template>
            </div>
            <template v-if="error">
                <div class="invalid-feedback d-block font-weight-bold mt-1">
                    {{ error }}
                </div>
                <hr class="mb-2 mt-3">
            </template>

            <div class="text-center mt-2">
                <button class="btn btn-link" type="button" :aria-label="ariaLabel" @click="handleOpenModalClicked">
                    Changer d'adresse...
                </button>
            </div>
        </template>

        <b-modal
            data-legacy
            :title="label"
            v-model="showModal"
            ok-only
            button-size="lg"
            footer-class="pt-sm-0 modal-footer--sticky"
            body-class="pb-sm-0"
            @ok="handleOkClicked"
        >
            <AddressList
                v-model="address"
                :allowed-countries="allowedCountries"
                :country-help="countryHelp"
                :is-delivery="isDelivery"
                selectable
                add-button-class="btn-secondary"
                ref="list"
            >
                <template v-if="profileAddressesUrl" v-slot:append-list>
                    <div class="list-group-item py-2">
                        <span class="small text-muted">
                            Les adresses sont modifiable dans
                            <a :href="profileAddressesUrl" target="_blank" @click.once="handleEditAddressesClicked">votre compte</a>
                        </span>
                    </div>
                </template>
            </AddressList>
        </b-modal>
    </div>
</template>
<script>
    import AddressList from "../../account/AddressList.vue";
    import AddressLabel from "../../account/AddressLabel.vue";

    export default {

        components: {
            AddressLabel,
            AddressList
        },
        props: {
            value: Object,
            name: String,
            label: String,
            profileAddressesUrl: String,
            countryHelp: String,
            allowedCountries: Array,
            isDelivery: Boolean,
            error: [String, Boolean],
        },
        data() {
            return {
                showModal: false,
                address: this.value && {
                    ...this.value
                },
            }
        },
        watch: {
            value: 'handleValueChanged',
        },
        computed: {
            ariaLabel() {
                return `Changer l'${this.label?.toLowerCase()}`;
            },
        },
        methods: {
            handleValueChanged() {
                this.address = this.value && {
                    ...this.value,
                };
            },
            handleOkClicked() {
                this.$emit('input', {
                    ...this.address,
                });
            },
            handleOpenModalClicked() {
                this.showModal = true;
            },
            handleEditAddressesClicked() {
                window.addEventListener('focus', this.handleWindowFocused)
            },
            handleWindowFocused() {
                this.$refs.list?.update();
            },
        },
        beforeDestroy() {
            window.removeEventListener('focus', this.handleWindowFocused);
        }
    }
</script>
