// import StoresPage from './pages/Stores.vue';
// import ProductPage from './pages/Product.vue';
// import StorePage from './pages/Store.vue';
import OrderPage from './pages/Order.vue';
import Page from './pages/Page.vue';
import DeliveryAddressPage from './pages/DeliveryAddress.vue';

import ProductItem from './products/ProductItem.vue';
// import ProductList from './products/ProductList.vue';
// import ProductRating from './ProductRating.vue';
// import ProductVisual from './products/ProductVisual.vue';
import ProductDeliveryInfos from './products/ProductDeliveryInfos.vue';
import ProductRow from './products/ProductRow.vue';

// import StoreSelector from './stores/StoreSelector.vue';
// import StorePanel from './StorePanel.vue';
import OrderHistory from './OrderHistory.vue';
// import QuickCart from './QuickCart.vue';
// import Search from './search/Search.vue';
import Loading from './Loading.vue';
import Confirm from './Confirm.vue';
// import CookiesConsent from './metrics/CookiesConsent.vue';
// import CookiesConsentRadios from './metrics/CookiesConsentRadios.vue';

// import StockLabel from './StockLabel.vue';

import StickySocials from './StickySocials.vue';
// import NavMenu from './NavMenu.vue';
// import NavMenuList from './NavMenuList.vue';
// import NewsletterModal from './NewsletterModal.vue';

// import Date from './Date.vue';
import CustomValueRadio from './CustomValueRadio.vue';
// import Carousel from './Carousel.vue';
// import VisualTabs from './VisualTabs.vue';
import CountrySelect from './CountrySelect.vue';

import Cart from './cart/Cart.vue';
import AddToCart from './AddToCart.vue';
import PaymentConfirm from './PaymentConfirm.vue';
import PaymentForm from './PaymentForm.vue';
import CouponForm from './cart/CouponForm.vue';
import SummaryCard from './cart/SummaryCard.vue';
import DeliveryModeSelector from './cart/DeliveryModeSelector.vue';
import GiftcardPaymentForm from './cart/GiftcardPaymentForm.vue';

// import MarketingLabel from './MarketingLabel.vue';

import GiftcardStoreSelector from './giftcard/GiftcardStoreSelector.vue';
import GiftcardSendToForm from './giftcard/SendToForm.vue';

import SizeObserver from './SizeObserver.vue';
// import TouchOnly from './TouchOnly.vue';
// import BackToTop from './BackToTop.vue';

import InterlisteSearchForm from './interliste/SearchForm.vue';
import InterlisteQuantityInput from './interliste/QuantityInput.vue';
import InterlisteList from './interliste/List.vue';
import InterlisteHeader from './interliste/Header.vue';
import InterlisteThumbnail from './interliste/Thumbnail.vue';
import InterlisteOrders from './interliste/Orders.vue';

// import StoresMap from './stores/StoresMap.vue';
// import StoreItem from './stores/StoreItem.vue';
// import StoresAutocomplete from './stores/StoresAutocomplete.vue';

// import Header from './Header.vue';
// import FormModal from './FormModal.vue';
// import Price from './Price.vue';
// import Gallery from './Gallery.vue';

import OrderComments from './account/OrderComments.vue';
import FidelityCard from './account/FidelityCard.vue';
// import PopularProducts from './tiles/PopularProducts.vue';

import AddressList from './account/AddressList.vue';
import CityField from './CityField.vue';

import DeliveryTypeCard from './order/delivery-address/DeliveryTypeCard.vue';
import UserAddressSelector from './order/delivery-address/UserAddressSelector.vue';
import ParcelShopSelector from './order/delivery-address/ParcelShopSelector.vue';

export default {
    // 'ek-header': Header,
    // 'ek-form-modal': FormModal,

    // 'ek-stores-page': StoresPage,
    // 'ek-product-page': ProductPage,
    // 'ek-store-page': StorePage,
    'ek-order-page': OrderPage,
    'ek-delivery-address-page': DeliveryAddressPage,
    'ek-page': Page,

    // 'ek-store-selector': StoreSelector,
    // 'ek-store-panel': StorePanel,
    // 'ek-stock-label': StockLabel,
    'ek-product-delivery-infos': ProductDeliveryInfos,

    'ek-order-history': OrderHistory,
    // 'ek-marketing-label': MarketingLabel,

    // 'ek-quick-cart': QuickCart,
    // 'ek-search': Search,
    // 'ek-gallery': Gallery,

    'ek-loading': Loading,
    'ek-confirm': Confirm,
    // 'ek-cookies-consent': CookiesConsent,
    // 'ek-cookies-consent-radios': CookiesConsentRadios,

    'ek-sticky-socials': StickySocials,
    // 'ek-nav-menu': NavMenu,
    // 'ek-nav-menu-list': NavMenuList,
    // 'ek-newsletter-modal': NewsletterModal,

    // 'ek-date': Date,
    'ek-custom-value-radio': CustomValueRadio,
    // 'ek-carousel': Carousel,
    // 'ek-visual-tabs': VisualTabs,
    'ek-country-select': CountrySelect,

    'ek-cart': Cart,
    'ek-delivery-mode-selector': DeliveryModeSelector,
    'ek-cart-summary-card': SummaryCard,
    'ek-add-to-cart': AddToCart,
    'ek-payment-form': PaymentForm,
    'ek-payment-confirm': PaymentConfirm,
    'ek-coupon-form': CouponForm,
    'ek-giftcard-payment-form': GiftcardPaymentForm,

    'ek-giftcard-store-selector': GiftcardStoreSelector,
    'ek-giftcard-send-to-form': GiftcardSendToForm,

    // 'ek-stores-map': StoresMap,
    // 'ek-store-item': StoreItem,
    // 'ek-stores-autocomplete': StoresAutocomplete,

    'ek-product-item': ProductItem,
    // 'ek-product-list': ProductList,
    // 'ek-product-rating': ProductRating,
    // 'ek-product-visual': ProductVisual,
    'ek-product-row': ProductRow,
    // 'ek-price': Price,

    'ek-product-ribbon': () => import('./tiles/ProductRibbon.vue'),
    'ek-product-ribbon-item': () => import('./tiles/ProductRibbonItem.vue'),
    // 'ek-popular-products': PopularProducts,

    'ek-order-comments': OrderComments,
    'ek-fidelity-card': FidelityCard,

    'ek-size-observer': SizeObserver,
    // 'ek-touch-only': TouchOnly,
    // 'ek-back-to-top': BackToTop,

    'ek-address-list': AddressList,
    'ek-city-field': CityField,

    'ek-user-address-selector': UserAddressSelector,
    'ek-parcel-shop-selector': ParcelShopSelector,
    'ek-delivery-type-card': DeliveryTypeCard,

    'interliste-search-form': InterlisteSearchForm,
    'interliste-quantity-input': InterlisteQuantityInput,
    'interliste-list': InterlisteList,
    'interliste-header': InterlisteHeader,
    'interliste-thumbnail': InterlisteThumbnail,
    'interliste-orders': InterlisteOrders,
}
