<script>
    import Vue from 'vue';
    import debounce from 'lodash/debounce';

    const sizeState = Vue.observable({
        viewportWidth: window.innerWidth,
    });

    window.addEventListener('resize', debounce(() => {
        sizeState.viewportWidth = window.innerWidth;
    }, 200));

    export default {
        functional: true,

        render(h, ctx) {
            return ctx.scopedSlots.default(sizeState);
        },
    }
</script>
