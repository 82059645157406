import axios from 'axios';

export const api = axios.create();

api.interceptors.response.use(config => config, error => {
    if(error.response?.status === 419) {
        alert(`Votre session n'est plus valide, appuyez sur Ok pour actualiser la page`);
        location.reload();
    }
    return Promise.reject(error);
});

export function getCart() {
    return api.get('/cart')
        .then(response => response.data.data)
}

export function putCartItem({ itemId, quantity }) {
    return api.put(`/cart/${itemId}`, { quantity })
        .then(response => response.data.data);
}

export function putCartItemOffer({ itemId }) {
    return api.put(`/cart/${itemId}/offer`)
        .then(response => response.data.data);
}

export function deleteCartItem(itemId) {
    return api.delete(`/cart/${itemId}`)
        .then(response => response.data.data);
}

export function postCartCoupon({ code }) {
    return api.post('/cart/coupon', { code })
        .then(response => response.data.data)
}

export function deleteCartCoupon() {
    return api.delete('/cart/coupon')
        .then(response => response.data.data);
}

export function postCartDeliveryMode({ mode }) {
    return api.post('/cart/delivery', { delivery_mode:mode })
        .then(response => response.data.data);
}

export function getCartCount() {
    return api.get('/cart/count')
        .then(response => response.data);
}


export function getCity(query) {
    const isPostalCode = /^[0-9]{2,5}$/.test(query);
    return isPostalCode
        ? getCitiesByPostalCode(query).then(cities => cities[0])
        : getCityByName(query);
}

export function getCitiesByPostalCode(postalCode) {
    return axios.get('https://geo.api.gouv.fr/communes', {
        params: {
            codePostal: postalCode,
            fields: 'centre',
            boost: 'population',
        }
    }).then(response => response.data);
}

export function getCityByName(name) {
    return axios.get('https://geo.api.gouv.fr/communes', {
        params: {
            nom: name,
            fields: 'centre',
            boost: 'population',
        }
    }).then(response => response.data[0]);
}

export function getCityAutocompleteResults(query) {
    return axios.get('https://api-adresse.data.gouv.fr/search', {
        params: {
            q: query,
            type: 'municipality',
            autocomplete: '1',
        },
    }).then(response => response.data);
}

export function getStores({ lat, lng, productId, storeId } = {}) {
    return api.post('/store/list', {
        lat,
        lng,
        product_id: productId,
        store_id: storeId,
    })
    .then(response => response.data.data);
}

export function postUserStore(storeId) {
    return api.post('/store/set-in-profile', {
        store_id: storeId,
    })
    .then(response => response.data.data);
}

export function postCartStore(storeId) {
    return api.post('/store/set-in-current-cart-only', {
        store_id: storeId,
    })
    .then(response => response.data.data)
}

export function postCartProduct({ productId }) {
    return api.post('/cart', {
        product_id: productId,
    })
    .then(response => response.data);
}


export function postProductRating({ productId, rating }) {
    return api.post(`/articles/noter/${productId}`, {
        note: rating,
    }).then(response => response.data);
}

export function getAccountStoreOrders() {
    return api.get('/compte/commandes/magasin/historique')
        .then(response => response.data)
}


export function getPopularProducts({ tileId, thumbnail_width }) {
    return api.get(`/popular_products/${tileId}`, {
        params: {
            thumbnail_width,
        }
    })
    .then(response => response.data)
}


export function getFidelityData() {
    return api.get(`/compte/get-fidelity-data`)
        .then(response => response.data);
}

export function getAutocompleteStores({ query, limit }) {
    return api.get('/autocomplete/stores', {
        params: {
            q: query,
            limit,
        }
    }).then(response => response.data.data);
}

export function getAutocompleteCategories({ query, limit }) {
    return api.get('/autocomplete/categories', {
        params: {
            q: query,
            limit,
        }
    }).then(response => response.data.data);
}

export function getAutocompleteBrands({ query, limit }) {
    return api.get('/autocomplete/brands', {
        params: {
            q: query,
            limit,
        }
    }).then(response => response.data.data);
}


export function postAppointment({ storeId, data }) {
    return api.post(`/store/${storeId}/appointment`, data)
        .then(response => response.data.data);
}

export function getAddresses() {
    return api.get('/compte/addresses')
        .then(response => response.data.data);
}

export function postAddress({ addressId, data }) {
    return api.post(`/compte/addresses/${addressId ?? ''}`, data)
        .then(response => response.data.data);
}

export function postAddressAsDefault(addressId) {
    return api.post(`/compte/addresses/${addressId}/set-as-default`)
        .then(response => response.data.data);
}

export function deleteAddress(addressId) {
    return api.delete(`/compte/addresses/${addressId}`);
}


export function postGiftcardCheck({ giftcard_num }) {
    return api.post(`/commande/giftcard-check`, { giftcard_num })
        .then(response => response.data.data);
}

export function getAlmaEligibility() {
    return api.get('/alma/eligibility')
        .then(response => response.data.data);
}
